import React, { useState } from 'react';
import AddNewEmployee from './components/AddNewEmployee';
import DisplayForm from './components/DisplayForm';
import Template from './components/Template';
import Select from './components/Select';
import Home from './components/Home';
import NavBar from './components/NavBar';
import { Route, Switch } from 'react-router-dom';

import NoMatchPage from './components/NoMatchPage';
import Footer from './components/Footer';

function Layout() {
  
  // eslint-disable-next-line no-unused-vars
  const [_tempo, setTempo] = useState("");

  return (
    <>
      <NavBar
        home={true}
        setTempo={setTempo}
      />
      <Footer />
      <Switch>
        <Route exact path="/" component={Home} />

        <Route
          exact
          path="/selectservice/AddNewEmplyee"
          component={AddNewEmployee}
        />
        <Route
          exact
          path="/selectservice/selectEmployee"
          component={Select}
        />
        <Route
          exact
          path="/selectservice/Generate/DisplayForm/:selectedID"
          component={DisplayForm}
        />
        <Route
          exact
          path="/selectservice/Generate/Template/:selectedID"
          component={Template}
        />
        <Route component={() => <NoMatchPage />} />
      </Switch>
    </>
  );
}

export default Layout;
