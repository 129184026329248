import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { SelectEmployee } from "./Styled_Components/SelectEmployee";
import { totalDataSelector } from "../redux/fetchSheet/selectors";

export default function MySelect() {
  const history = useHistory();
  const data = useSelector(totalDataSelector);

  const handleChange = (obj) => {
    history.push(`/selectservice/Generate/DisplayForm/${obj.value}`);
  };

  return (
    <SelectEmployee>
      <form>
        <label>
          <h3>Select Employee:</h3>
          <Select
            onChange={handleChange}
            options={data.map((data, index) => {
              return { value: data.employeeID, label: data.fullName };
            })}
          />
        </label>
      </form>
    </SelectEmployee>
  );
}
