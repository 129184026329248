import styled from "styled-components";
import { AiOutlineLogout } from "react-icons/ai";

export const StyledNav = styled.nav`
  // height: 90px;
  height: 12vh;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  color: rgb(34, 137, 255);
  background-color: rgb(244, 247, 250);
`;

export const StyledNavLogo = styled.img`
  max-height: 80px;
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 150;
  cursor: pointer;
`;

export const StyledNavContainer = styled.div`
  height: 90px;
  z-index: 3;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledNavItem = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex: ${({ flex }) => (flex ? 2 : 1)};
`;
export const StyledNavItems = styled.div`
  height: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const StyledNavBtn = styled.div`
  width: 100px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(34, 137, 255);
  border-radius: 5px;
  color: #fff;
  transition: all 0.8s ease;
  &:hover {
    background-color: #2e294e;
  }
`;

export const StyledLogout = styled(AiOutlineLogout)`
  height: 30px;
  width: 30px;
  cursor: pointer;
`;

export const StyledLogoutContainer = styled.div`
  width: 60px;
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  right: 1.5rem;
  top: 1rem;
`;

export const StyledNavBtnContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.8s ease;
  z-index: 2;
`;
