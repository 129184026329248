import { createReducer } from "@reduxjs/toolkit";
import {
  fetchSheetDataRequested,
  fetchSheetDataSucceded,
  fetchSheetDataRejected,
  addToForm,
} from "./actions";

const initialState = {
  fetchLoading: false,
  fetch_error: null,
  data: [],
  formData: {},
};

const fetchFromSheetReducer = createReducer(initialState, {
  [fetchSheetDataSucceded.type]: (state, action) => {
    state.fetchLoading = false;
    state.data = action.payload;
  },
  [fetchSheetDataRequested.type]: (state) => {
    state.fetchLoading = true;
  },
  [fetchSheetDataRejected.type]: (state, action) => {
    state.fetchLoading = false;
    state.fetch_error = action.payload;
  },

  [addToForm.type]: (state, action) => {
    state.formData = action.payload;
  },
});

export default fetchFromSheetReducer;
