import React from "react";
import {
  MainSidebar,
  SidebarContainer,
  SidebarItems,
} from "./Styled_Components/Sidebar";

function Sidebar({ sidebar, setTempo, showSidebar }) {
  return (
    <MainSidebar sidebar={sidebar}>
      <SidebarContainer>
        <SidebarItems
          onClick={() => {
            setTempo("/selectservice/selectEmployee");
            showSidebar();
          }}
          to="/selectservice/selectEmployee"
        >
          Generate Signature
        </SidebarItems>
        <SidebarItems
          onClick={() => {
            setTempo("/selectservice/AddNewEmplyee");
            showSidebar();
          }}
          to="/selectservice/AddNewEmplyee"
        >
          AddNewEmployee
        </SidebarItems>
      </SidebarContainer>
    </MainSidebar>
  );
}

export default Sidebar;
