import React from "react";
import { useHistory } from "react-router";
import styled from "styled-components";

export const StyledBack = styled.div`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

function Back() {
  const history = useHistory();
  return (
    <StyledBack>
      <div onClick={() => history.goBack()}>back</div>
    </StyledBack>
  );
}

export default Back;
