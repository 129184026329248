import styled from "styled-components";
import {Container} from '../../globalStyle'

export const MainContainer = styled(Container)`
  position:absolute;
  top:12vh;
  justify-content:center;
  align-items:center;
  h5 {
  }
  p {
    margin-top: 5px;
  }
  b {
    font-weight: bold;
    font-size: 16px;
  }
`;

export const MainForm = styled.form`
  margin-left: 11%;
  margin: 15px;
  padding: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media screen and (max-width:800px){
    grid-template-columns: 1fr;
  }
  background-color: rgb(244, 247, 250);
`;

export const FormElement = styled.div`
  padding-top: 5px;
  padding: 0px 5px;
  display: flex;
  flex-direction: column;
  height:60px;
`;
export const FormSubmitBtnContainer = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 5px;
  justify-content: flex-end;
  
`;
export const FormSubmitButton = styled.button`
  margin-top: 5%;
  cursor: pointer;
  padding: 3px 8px;
  height: 35px;
  font-size: 16px;
  border-radius: 5px;
  border: none;
  background-color: rgb(34, 137, 255);
  color: #fff;
`;
