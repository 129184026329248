import styled from 'styled-components'
import { Container } from '../../globalStyle'

export const StyledTemplateContainer = styled(Container)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #EEEEEE;
  a {
    text-decoration: none !important;
  }
  position: absolute;
  top:12vh;
`

export const StyledBackBtnContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
`
export const StyledBackBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  // width: 10px;
  height: 25px;
  // margin-left: 25%;
  border-radius: 5px;
  @media screen and (max-width: 750px) {
    margin-top: 1rem;
    margin-left: 20%;
  }

  &:hover {
    // background-color: ${(props) => props.theme.primaryText};
    text-decoration: underline;
  }
`
