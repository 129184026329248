import React, { useState, useEffect } from "react";
import { Prompt, useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addToForm } from "../redux/fetchSheet/actions";
import { totalDataSelector } from "../redux/fetchSheet/selectors";
import { Fragment } from "react";
import {
  FormElement,
  FormSubmitBtnContainer,
  FormSubmitButton,
  MainContainer,
  MainForm,
} from "./Styled_Components/DisplayForm";
import Back from "./Back";

export default function DisplayForm() {
  const history = useHistory();
  const myData = useSelector(totalDataSelector);
  const dispatch = useDispatch();
  const { selectedID } = useParams();

  const [isEntered, setIsEntered] = useState(false);
  const [filtered, setFiltered] = useState({});

  const [employeeData, setEmployeeData] = useState({
    employeeID: "",
    name: "",
    surname: "",
    email: "",
    position: "",
    mobile_phone: "",
    telePhone: "",
    address: "",
    picture: "",
    company: "",
    linkedin: "",
    twitter: "",
    website: "",
    personal_profile:"",
  });

  useEffect(() => {
    myData &&
      setFiltered(myData.filter((myData) => myData.employeeID === selectedID));
  }, [selectedID, myData]);

  useEffect(() => {
    if (filtered && filtered[0]) {
      setEmployeeData(filtered[0]);
    }
  }, [filtered]);

  const handleChange = (e) => {
    console.log(e);
    setEmployeeData({ ...employeeData, [e.target.name]: e.target.value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(employeeData);
    setEmployeeData(employeeData);
    dispatch(addToForm(employeeData));
    history.push(`/selectservice/Generate/Template/${selectedID}`);
  };
  const handleFocus = () => {
    setIsEntered(true);
  };
  const handleOffFocus = () => {
    setIsEntered(false);
  };

  if (!employeeData.employeeID) {
    return (
      <div>
        <p>Such Employee doesn't exist</p>
      </div>
    );
  }
  console.log(isEntered ? "Yes" : "No");
  return (
    <Fragment>
      <Prompt
        when={isEntered}
        message={() =>
          "Are you sure you want to leave this page? you may loose all the changes you have made sofar"
        }
      />
      <MainContainer>
        <div style={{
          width: "60%",
          display: "flex",
          flexDirection: "column"
        }}>
  

        <MainForm onSubmit={handleSubmit} onFocus={handleFocus}>
          <FormElement>
            <label>
              <b>Employee ID </b>
            </label>
            <input
              type="text"
              name="employeeID"
              value={employeeData.employeeID}
              onChange={handleChange}
              readOnly
            ></input>
          </FormElement>
          <FormElement>
            <label>
              <b>Name </b>
            </label>
            <input
              type="text"
              name="name"
              value={employeeData.name}
              onChange={handleChange}
            ></input>
          </FormElement>
          <FormElement>
            <label>
              <b>Surname </b>
            </label>
            <input
              type="text"
              name="surname"
              value={employeeData.surname}
              onChange={handleChange}
            ></input>
          </FormElement>
          <FormElement>
            <label>
              <b>Email </b>
            </label>
            <input
              type="text"
              name="email"
              value={employeeData.email}
              onChange={handleChange}
            ></input>
          </FormElement>
          <FormElement>
            <label>
              <b>Position </b>
            </label>
            <input
              type="text"
              name="position"
              value={employeeData.position}
              onChange={handleChange}
            ></input>
          </FormElement>
          <FormElement>
            <label>
              <b>Cellphone </b>
            </label>
            <input
              type="text"
              name="mobile_phone"
              value={employeeData.mobile_phone}
              onChange={handleChange}
            ></input>
          </FormElement>
          <FormElement>
            <label>
              <b>Telephone</b>
            </label>
            <input
              type="text"
              name="telePhone"
              value={employeeData.telePhone}
              onChange={handleChange}
            ></input>
          </FormElement>
        
          <FormElement>
            <label>
              <b>Profile Picture </b>
            </label>
            <input
              type="text"
              name="picture"
              value={employeeData.picture}
              onChange={handleChange}
            ></input>
          </FormElement>
          <FormElement>
            <label>
              <b>Twitter</b>
            </label>
            <input
              type="text"
              name="company"
              value={employeeData.twitter}
              onChange={handleChange}
            ></input>
          </FormElement>
          <FormElement>
            <label>
              <b>Linkedin</b>
            </label>
            <input
              type="text"
              name="linkedin"
              value={employeeData.linkedin}
              onChange={handleChange}
            ></input>
          </FormElement>
          <FormElement>
            <label>
              <b>Personal Profile</b>
            </label>
            <input
              type="text"
              name="personal_profile"
              value={employeeData.personal_profile}
              onChange={handleChange}
            ></input>
          </FormElement>
         
          <FormSubmitBtnContainer>
            <FormSubmitButton onClick={handleOffFocus} type="submit">
              Generate Signature
            </FormSubmitButton>
          </FormSubmitBtnContainer>
        </MainForm>
          <Back />
        </div>
      </MainContainer>
    </Fragment>
  );
}
