import styled from "styled-components";

export const MainContainer = styled.div`
  width: ${({ isEntered }) => (isEntered ? "75%" : "45%")};
  height: 450px;
  align-items: center;
  margin-top: 15rem;
  padding-top: 30px;
  opacity: 0.7;
  border-radius: 5px;
  position: absolute;
  background-color: rgb(244, 247, 250);
  transition: all 0.8s ease;
`;

export const MainForm = styled.form`
  padding: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

export const FormElement = styled.div`
  padding: 15px;
  display: flex;
`;

export const FormSubmit = styled.input`
  cursor: pointer;
  padding: 0px 15px;
  background-color: rgb(34, 137, 255);
  color: white;
  width: ${({ isEntered }) => (isEntered ? "120px" : "100px")};
  height: ${({ isEntered }) => (isEntered ? "35px" : "25px")};
  margin-left: ${({ isEntered }) => (isEntered ? "26%" : "48%")};
  border-radius: 5px;
  &:hover {
    background-color: black;
    color: white;
  }
  transition: all 0.8s ease;
`;

export const FormLabel = styled.label`
  flex: 1;
  color: rgb(34, 137, 255);
`;
export const FormInput = styled.input`
  flex: ${({ isEntered }) => (isEntered ? 3 : 1)};
  transition: all 0.8s ease;
`;
