import styled from "styled-components";

export const StyledFooterContainer = styled.div`
  height: 5vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(244, 247, 250);
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
`;
export const StyledFooterElementHolder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const StyledFooterElement = styled.span`
  color: rgb(34, 137, 255);
  h3 {
    font-size: 16px;
  }
  p {
    font-size: 12px;
  }
`;
