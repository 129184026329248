const NoMatchPage = () => {
  return (
    <>
      <h3>404 - Not found</h3>
      <h6 style={{ color: "red" }}>The page being requested doesn't exist</h6>
    </>
  );
};

export default NoMatchPage;
