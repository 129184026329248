import React from "react";
import { Homepage } from "./Styled_Components/Homepage";

function Home() {
  return (
    <Homepage>
      <h3>Welcome to signature application</h3>
      <h4>FOR INTERNAL USE ONLY</h4>
      <h4>Select an action from the menu in the navigation bar</h4>
    </Homepage>
  );
}

export default Home;
