import { createSelector } from "@reduxjs/toolkit";

const selectData = (state) => state.sheet;

export const fetchSelector = createSelector(
  [selectData],
  (sheet) => sheet.fetch
);

export const totalDataSelector = createSelector(
  [fetchSelector],
  (fetch) => fetch.data
);
export const formDataSelector = createSelector(
  [fetchSelector],
  (fetch) => fetch.formData
);
