import React, { useState } from "react";
import { useHistory } from "react-router";
import {
  StyledNav,
  StyledNavItems,
  StyledNavContainer,
  StyledNavItem,
  StyledNavBtn,
  StyledNavLogo,
  StyledNavBtnContainer,
} from "./Styled_Components/NavBar";
import { BiChevronLeft } from "react-icons/bi";
import { BiChevronRight } from "react-icons/bi";
import Sidebar from "./Sidebar";

function NavBar({ setTempo }) {
  const [sidebar, setSidebar] = useState(false);
  const history = useHistory();

  const showSidebar = () => {
    setSidebar(!sidebar);
  };

  return (
    <StyledNav>
      <StyledNavContainer>
        <StyledNavItem>
          <StyledNavLogo
            onClick={() => history.push("/")}
            src="https://www.neosperience.com/wp-content/themes/neos/images/logo.png"
          />
        </StyledNavItem>
        <StyledNavItem flex>
          <StyledNavItems>
            <h2>Gmail Signature </h2>
            <p> For internal use only</p>
          </StyledNavItems>
        </StyledNavItem>
        <StyledNavItem>
          <StyledNavBtnContainer sidebar={sidebar}>
              <StyledNavBtnContainer>
                <StyledNavBtn onClick={showSidebar}>
                  Action
                  {sidebar ? <BiChevronLeft /> : <BiChevronRight />}
                </StyledNavBtn>
              </StyledNavBtnContainer>
              {/*<StyledLogoutContainer onClick={logAndRedirect}>*/}
              {/*  <h4>LOGOUT</h4>*/}
              {/*  <StyledLogout />*/}
              {/*</StyledLogoutContainer>*/}
          </StyledNavBtnContainer>
        </StyledNavItem>
      </StyledNavContainer>
      <Sidebar
        setTempo={setTempo}
        sidebar={sidebar}
        showSidebar={showSidebar}
      />
    </StyledNav>
  );
}

export default NavBar;
