import styled, { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  
  
}
body {
    height: 100%;
    width: 100%;
    margin: 0;
}

`

export const Container = styled.div`
  display: flex;
  height: calc(100vh - 17vh);
  // height: 100vh;
  width: 100vw;
`
