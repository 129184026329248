export const createEmail = (email) => {
 return `mailto:${email}?subject= La firma della tua email aziendale&body=   Caro collega, di seguito la procedura per l’inserimento della firma.%0A
  %0A
   1. Utilizzando come browser o Safari o Firefox, entra sul tuo profilo Gmail. %0A 
  2. Copia la firma, stando attento a non copiare la riga iniziale e finale. Copia però lo spazio sopra la foto o, se non si ha la foto in firma, sopra il proprio nome.%0A 
  3. Vai sulle impostazioni del tuo profilo%0A 
  4. Clicca: crea una nuova signature (non modificare una firma già esistente).%0A 
  5. Incolla ciò che hai copiato.%0A 
  6. Sotto, nelle Impostazioni firma predefinita, seleziona sia in NUOVE EMAIL, che in RISPOSTE/INOLTRO, la firma appena creata.%0A
   7. Infine salva il tutto.%0A %0A %0A 

     -------------------%0A%0A%0A%20%3C--%20%20%20incolla%20la%20firma%20qua%20--%3E%0A%0A%0A    -------------------%0A%0A


   Ed ecco fatto! %0A 
  Se ci sono problemi, rispondi pure a quest’email %0A %0A
   Grazie!
  ` 
}