import React, { useState } from 'react'
import Select from 'react-select'
import {
  StyledBackBtn,
  StyledBackBtnContainer,
  StyledTemplateContainer,
} from './Styled_Components/mainTemplate'
import { createEmail } from './createEmail'



const MainTemplate = ({
  activeEmployee,
  copy,
  download,
  returnBack,
}) => {
  const [action, setAction] = useState({ value: "none", label: 'none' })
  const options = [
    { value: 'copy', label: 'Copy' },
    { value: 'download', label: 'Download' },
  ]

  const fullName = `${activeEmployee?.name} ${activeEmployee?.surname}`;
  const email = createEmail(activeEmployee?.email);

  const handleClickEvent = () => {
    if (action.value === "copy") {
      copy();
    } else {
      download();
    }
  }

 

  return (
    <StyledTemplateContainer>
      <a style={{ right: "120px", position:"absolute", top:"50px"}} href={email}>
        <buttto style={{backgroundColor:"rgb(34, 137, 255)", color:"white", padding:"10px", borderRadius:"5px"}}>
          Send mail to the Employee
        </buttto>
      </a>   
      <div className="container" 
        style={{
          textAlign: "left",
          margin: "auto", 
          position: "relative"
        }}>
    
        <div className="template-container" 
          style={{ 
            borderRadius:"11px",
            backgroundColor: "#fff",
            margin: "auto auto",
            padding: "1rem",
            width: "100%",
            maxWidth: "500px",
            boxShadow: "2px 2px #E7E7E7"
          }}>
          <div style={{width:"100%"}}>
            <div id="main">          
            <table>
            
                <div style={{ width: "100%", maxWidth: "700px" }}>
                  {
                  activeEmployee?.picture &&        
                  <div className="profile-image"
                    style={{
                      position: "relative",
                      height: "80px",
                      width: "80px",
                      margin: "0 5px",
                      marginTop: "10px",  
                      marginBottom: "15px",  
                      textAlign: "center",                                         
                    }}
                  >
                    
                    <img
                        style={{
                          height: "100%",
                          width: "100%",
                          borderRadius: "50%",
                          }}
                          src={activeEmployee?.picture}
                          alt="profile_picture"
                    />   
                  </div>
                }
                  <table style={{marginBottom:"1px"}}>
                    <tr>
                      <td>
                          <span style={{
                            color: "#0052EE",
                            paddingTop: "15px",
                            textTransform: "uppercase",
                            fontSize: "12px",
                            fontWeight: "bold",
                            fontFamily: "Space Mono Bold, Courier New",
                          }}>
                            {activeEmployee && fullName}
                          </span>
                      </td>                  
                    </tr>
                  </table>
                  <table style={{marginBottom:"1px"}}>
                    <tr>  
                      <td>
                        <span style={{fontSize: "11px", color: "#232323",marginRight:"4px", fontFamily: "arial, sans-serif, Biotif,Arial MT" }}>{activeEmployee?.position}</span><span style={{color:"#0052EE", fontSize:"11px"}}>•</span> <span style={{ marginLeft: "3px", fontSize: "11px",color:"#0052EE",textTransform: 'uppercase',  fontWeight: "bold", fontFamily: "Space Mono Bold, Courier New" }}>Neosperience</span>             
                      </td>
                    </tr>
                  </table>
                  
                  {
                  activeEmployee?.email &&
                    
                  <table style={{marginBottom:"1px"}}>  
                    <tr>
                        <td>
                          <a href={`mailto:${activeEmployee?.email}`} style={{ color: "#232323", cursor: "pointer", lineHeight: "15px", fontSize: "11px", paddingRight: "3px", fontFamily: "arial, sans-serif,Biotif,Arial MT", textDecoration: "none" }}>
                            {`${activeEmployee?.email}`}
                           </a>  
                        </td>
                    </tr>
                  </table>
                    
                  }
                       
                  
                  <table style={{marginBottom:"1px"}}>
                    <tr>
                      {
                        activeEmployee?.mobile_phone &&
                        <td>                    
                          <a href={`tel:${activeEmployee?.mobile_phone}`} style={{ color: "#232323", marginTop: "2px", cursor: "pointer", lineHeight: "15px", fontSize: "11px", marginRight: "4px", fontFamily: "arial, sans-serif,Biotif,Arial MT", textDecoration: "none" }}>
                              {`+39 ${activeEmployee?.mobile_phone}`}  
                          </a>
                          <span style={{ color: "#0052EE", fontSize: "11px" }}>•</span>
                        </td>
                      }
                      <td>
                        <a style={{ color: "#232323",marginLeft:"3px", cursor: "pointer", lineHeight: "16px", fontSize: "11px", marginRight: "6px", fontFamily: "arial, sans-serif,Biotif,Arial MT", textDecoration: "none" }} href='https://www.neosperience.com/'>
                          www.neosperience.com
                        </a>
                      </td>
                     
                    </tr>
                  </table>
                      
                                               
                  <table>
                    <tr>
                      {
                        activeEmployee?.linkedin &&
                        <td>
                          <a className="linkedin" href={`${activeEmployee?.linkedin}`}  style={{ color: "#232323", marginTop: "2px", cursor: "pointer", lineHeight: "15px", fontSize: "11px", paddingRight: "3px", fontFamily: "arial, sans-serif,Biotif,Arial MT", textDecoration: "none" }}>
                            Linkedin
                          </a>
                        </td>
                      }
                      {
                        activeEmployee?.twitter &&
                        <td>
                          <span style={{color:"#0052EE", fontSize:"11px"}}>•</span>
                          <a className="linkedin" href={`${activeEmployee?.twitter}`}  style={{ color: "#232323",marginLeft:"3px", marginTop: "2px", cursor: "pointer", lineHeight: "15px", fontSize: "11px", paddingRight: "3px", fontFamily: "arial, sans-serif,Biotif,Arial MT", textDecoration: "none" }}>
                           Twitter
                          </a>
                       </td>
                      }
                          
                      {
                        activeEmployee?.personal_profile &&
                        <td>
                        <span style={{color:"#0052EE", fontSize:"11px"}}>• </span>
                          <a style={{ color: "#232323", marginTop: "2px", cursor: "pointer",marginLeft:"3px", lineHeight: "15px", fontSize: "11px", paddingRight: "3px", fontFamily: "arial, sans-serif,Biotif,Arial MT", textDecoration: "none" }} href={`${activeEmployee.personal_profile}`}>
                            Personal Profile
                          </a>
                       </td>
                      }
                    </tr>
                  </table>
              
              </div>
            </table>
          </div> 
        </div>
        
        </div>
        <div style={{ padding: "15px 0px", display: 'flex', flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
          <h3 style={{ padding: "10px 0px" }}>How do you want to get the signature?</h3>
          <div style={{ width: "200px", height: "60px" }} >
            <Select onChange={(value) => value && setAction(value)} options={options} placeholder="Select one option" />
            <button onClick={handleClickEvent} style={{ width: "50px", height: "30px", display: action.value === "none" ? "none" : "block", margin: "5px 0px", borderRadius: "3px", padding: "3px 0px", color: "#fff", backgroundColor: " #0958F0" }}>GO</button>
          </div>             
        </div>

        <StyledBackBtnContainer>

          <StyledBackBtn onClick={returnBack}>Back</StyledBackBtn>
          
        </StyledBackBtnContainer>
      </div>   
       
   
    </StyledTemplateContainer>
  )
}

export default MainTemplate
