import { createAction } from "@reduxjs/toolkit";
import * as types from "./actionTypes";

export const addToForm = createAction(types.ADD_TO_FORM);
export const fetchSheetDataRequested = createAction(
  types.FETCH_SHEET_DATA_REQUESTED
);
export const fetchSheetDataRejected = createAction(
  types.FETCH_SHEET_DATA_REJECTED
);
export const fetchSheetDataSucceded = createAction(
  types.FETCH_SHEET_DATA_SUCCEDED
);
