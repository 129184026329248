import { takeLatest, put, call } from "redux-saga/effects";
import * as types from "./actionTypes";
import { fetchSheetDataRejected, fetchSheetDataSucceded } from "./actions";
import fetchDataApi from "./fetchDataApi";
import { startLogin } from '../../login';

function* asyncFetchData() {
  try {
    const data = yield call(fetchDataApi);
    yield put(fetchSheetDataSucceded(data));
  } catch (error) {
    const { status, data } = error.response;
    if (status === 401) startLogin();

    yield put(fetchSheetDataRejected(data));
  }
}

function* fetchSaga() {
  yield takeLatest(types.FETCH_SHEET_DATA_REQUESTED, asyncFetchData);
}

export default fetchSaga;
