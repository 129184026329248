import {
  StyledFooterContainer,
  StyledFooterElement,
  StyledFooterElementHolder,
} from "./Styled_Components/Footer";

function Footer() {
  return (
    <StyledFooterContainer>
      <StyledFooterElementHolder>
        <StyledFooterElement>
          <h3>Neosperience SPA, 2021 G.C</h3>
        </StyledFooterElement>
        <StyledFooterElement>
          <p>Milan, Italy</p>
        </StyledFooterElement>
      </StyledFooterElementHolder>
    </StyledFooterContainer>
  );
}

export default Footer;
