import { formDataSelector } from '../redux/fetchSheet/selectors'
import { useSelector } from 'react-redux'
import MainTemplate from './mainTemplate'
import { useHistory } from 'react-router'
import { useState } from 'react'

function Template() {
  const history = useHistory()

  const returnBack = () => {
    history.goBack()
  }

  const [activeEmployee, setActiveEmployee] = useState(useSelector(formDataSelector));
  if (activeEmployee.company === undefined) {
    setActiveEmployee(JSON.parse(localStorage.getItem('activeEmployee')))
  } else {
     localStorage.setItem('activeEmployee', JSON.stringify(activeEmployee))
  }

  const selectCopy = () => {
    const container = document.getElementById('main')
    const startOffset = 0
    const endOffset = 1
    const range = document.createRange()
    container && range.setStart(container, startOffset)
    container && range.setEnd(container, endOffset)
    const selObj = window.getSelection()
    selObj && selObj.removeAllRanges()
    selObj && selObj.addRange(range)
    range.toString()
    document.execCommand('copy')
    setTimeout(() => {
      selObj && selObj.removeAllRanges()
    }, 500)
  }

  const selectDownload = () => {
    const container = document.getElementById('main')
    const startOffset = 0
    const endOffset = 1
    const range = document.createRange()
    container && range.setStart(container, startOffset)
    container && range.setEnd(container, endOffset)
    const newDoc = range.startContainer 
    const doc = newDoc.innerHTML
    const element = document.createElement('a')
    element.style.display = 'none'
    element.setAttribute(
      'href',
      'data:text/plain;chartset =utf-8,' + encodeURIComponent(doc),
    )
    element.setAttribute(
      'download',
      `${activeEmployee?.name} ${activeEmployee?.surname} Gmail_Signature`,
    )
    element.click()
  }

  return (
  
    <>
      <MainTemplate
        returnBack={returnBack}
        activeEmployee={activeEmployee}
        copy={selectCopy}
        download={selectDownload}
      />
      </> 
  )
}

export default Template
