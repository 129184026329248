import createSagaMiddleware from "redux-saga";
import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import fetchFromSheetReducer from "../fetchSheet/fetchFromSheetReducer";
import writeOnSheetReducer from "../writeSheet/writeOnSheetReducer";
import { fork, all } from "redux-saga/effects";
import fetchSaga from "../fetchSheet/fecthDataSaga";
import writeSaga from "../writeSheet/addNewEmployeeSaga";

function* rootSaga() {
  yield all([fork(fetchSaga), fork(writeSaga)]);
}

const rootReducer = combineReducers({
  fetch: fetchFromSheetReducer,
  write: writeOnSheetReducer,
});

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: { sheet: rootReducer },
  middleware: [...getDefaultMiddleware({ thunk: false }), sagaMiddleware],
});
sagaMiddleware.run(rootSaga);
export default store;
