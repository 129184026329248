import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Prompt } from "react-router";
import { writeSheetDataRequested } from "../redux/writeSheet/actions";
import { statusSelector } from "../redux/writeSheet/selectors";
import {
  FormElement,
  FormInput,
  FormLabel,
  FormSubmit,
  MainContainer,
  MainForm,
} from "./Styled_Components/AddNewEmployee";

export default function AddNewEmployee() {
  const a = "you have successfully added the new Employee to the data sheet!";
  const status = useSelector(statusSelector);
  const dispatch = useDispatch();

  const [isEntered, setIsEntered] = useState(false);
  const [flag, setFlag] = useState(false);
  const [renderData, setRenderData] = useState("");
  const [newData, setData] = useState({});

  const {
    name,
    surname,
    email,
    position,
    employeeId,
    cellPhone,
    telePhone,
    website,
    address,
    image,
    personal_profile,
  } = newData;

  const handleChange = (e) => {
    setData({ ...newData, [e.target.name]: e.target.value });
  };
  const handleFocus = () => {
    setIsEntered(true);
  };
  const handleOffFocus = () => {
    setIsEntered(false);
  };
  useEffect(() => {
    setRenderData(status);
    setTimeout(() => {
      setRenderData("");
    }, 5000);
  }, [status, flag]);
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(writeSheetDataRequested(newData));
    setFlag(!flag);
    handleOffFocus();
  };
  console.log(isEntered);
  return (
    <Fragment>
      <Prompt
        when={isEntered}
        message={() =>
          "Are you sure you want to leave this page? you may loose all the changes you have made sofar"
        }
      />
      <MainContainer isEntered={isEntered}>
        <MainForm onSubmit={handleSubmit} onFocus={handleFocus}>
          <FormElement>
            <FormLabel>Employee ID</FormLabel>
            <FormInput
              type="text"
              name="employeeId"
              value={employeeId && employeeId}
              onChange={handleChange}
              isEntered={isEntered}
              placeholder="This field is very important and mandatory!"
            ></FormInput>
          </FormElement>
          <FormElement>
            <FormLabel> Name</FormLabel>
            <FormInput
              type="text"
              name="name"
              value={name && name}
              onChange={handleChange}
              isEntered={isEntered}
            ></FormInput>
          </FormElement>
          <FormElement>
            <FormLabel>Surname</FormLabel>
            <FormInput
              type="text"
              name="surname"
              value={surname && surname}
              onChange={handleChange}
              isEntered={isEntered}
            ></FormInput>
          </FormElement>
          <FormElement>
            <FormLabel>Email</FormLabel>
            <FormInput
              type="text"
              name="email"
              value={email && email}
              onChange={handleChange}
              isEntered={isEntered}
            ></FormInput>
          </FormElement>
          <FormElement>
            <FormLabel>Position</FormLabel>
            <FormInput
              type="text"
              name="position"
              value={position && position}
              onChange={handleChange}
              isEntered={isEntered}
            ></FormInput>
          </FormElement>
          <FormElement>
            <FormLabel>CellPhone</FormLabel>
            <FormInput
              type="text"
              name="cellPhone"
              value={cellPhone && cellPhone}
              onChange={handleChange}
              isEntered={isEntered}
            ></FormInput>
          </FormElement>
          <FormElement>
            <FormLabel>Telephone</FormLabel>
            <FormInput
              type="text"
              name="telePhone"
              value={telePhone && telePhone}
              onChange={handleChange}
              isEntered={isEntered}
            ></FormInput>
          </FormElement>
          <FormElement>
            <FormLabel>Website</FormLabel>
            <FormInput
              type="text"
              name="website"
              value={website && website}
              onChange={handleChange}
              isEntered={isEntered}
            ></FormInput>
          </FormElement>
          <FormElement>
            <FormLabel>Address</FormLabel>
            <FormInput
              type="text"
              name="address"
              value={address && address}
              onChange={handleChange}
              isEntered={isEntered}
            ></FormInput>
          </FormElement>
          <FormElement>
            <FormLabel>Profile Picture</FormLabel>
            <FormInput
              type="text"
              name="image"
              value={image && image}
              onChange={handleChange}
              isEntered={isEntered}
            ></FormInput>
          </FormElement>
            <FormElement>
            <FormLabel>Personal Profile</FormLabel>
            <FormInput
              type="text"
              name="personal_profile"
              value={personal_profile && personal_profile}
              onChange={handleChange}
              isEntered={isEntered}
            ></FormInput>
          </FormElement>
          <FormSubmit isEntered={isEntered} type="submit"></FormSubmit>
          <p
            style={{
              display: status ? "block" : "none",
              color: status === a ? "green" : "red",
            }}
          >
            {renderData && renderData}
          </p>
        </MainForm>
      </MainContainer>
    </Fragment>
  );
}
