import { takeLatest, put, call } from 'redux-saga/effects';
import * as types from './actionTypes';
import {
  writeSheetDataRejected,
  writeSheetDataSucceded,
  toggleStatusFlag,
} from './actions';
import AddNewEmployeeApi from './addNewEmployeeApi';
import { startLogin } from '../../login';

function* asyncWriteData(action) {
  try {
    const { status, data } = yield call(() => AddNewEmployeeApi(action.payload));
    if (status < 400) {
      yield put(
        toggleStatusFlag(
          'You have successfully added the new Employee to the data sheet!',
        ),
      );
      yield put(writeSheetDataSucceded(data));
    } else {
      yield put(
        toggleStatusFlag(data.message || 'The Employee you\'re trying to add already Exist'),
      );
    }
  } catch (error) {
    const { status, data } = error.response;
    if (status === 401) startLogin();

    yield put(writeSheetDataRejected(data));
    yield put(toggleStatusFlag(data.message || 'There was an error in the network or server'));
  }
}

function* writeSaga() {
  yield takeLatest(types.WRITE_SHEET_DATA_REQUESTED, asyncWriteData);
}

export default writeSaga;
