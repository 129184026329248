import { createReducer } from "@reduxjs/toolkit";
import {
  writeSheetDataRequested,
  writeSheetDataSucceded,
  writeSheetDataRejected,
  toggleStatusFlag,
} from "./actions";

const initialState = {
  writeLoading: false,
  write_error: null,
  write_response: {},
  status: "",
};

const writeOnSheetReducer = createReducer(initialState, {
  [writeSheetDataRequested.type]: (state) => {
    state.writeLoading = true;
  },
  [writeSheetDataSucceded.type]: (state, action) => {
    state.writeLoading = false;
    state.write_response = action.payload;
    state.stat = true;
  },
  [writeSheetDataRejected.type]: (state, action) => {
    state.writeLoading = false;
    state.write_error = action.payload;
  },
  [toggleStatusFlag.type]: (state, action) => {
    state.status = action.payload;
  },
});

export default writeOnSheetReducer;
