import { createAction } from "@reduxjs/toolkit";
import * as types from "./actionTypes";

export const writeSheetDataRequested = createAction(
  types.WRITE_SHEET_DATA_REQUESTED
);
export const writeSheetDataSucceded = createAction(
  types.WRITE_SHEET_DATA_SUCCEDED
);
export const writeSheetDataRejected = createAction(
  types.WRITE_SHEET_DATA_REJECTED
);
export const toggleStatusFlag = createAction(types.TOGGLE_STATUS_FLAG);
