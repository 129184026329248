import React, { useCallback, useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { useDispatch } from "react-redux";
import Layout from "./Layout";
import "./index.css";
import { fetchSheetDataRequested } from "./redux/fetchSheet/actions";
const App = () => {
  const dispatch = useDispatch();

  const logout = useCallback(() => {
    console.log(logout);
  }, []);

  useEffect(() => {
    dispatch(fetchSheetDataRequested());
  }, [dispatch]);

  return (
    <div className="App">
      <Router>
        <Layout />
      </Router>
    </div>
  );
};

export default App;
