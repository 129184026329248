import axios from "axios";

const API_URL = process.env.REACT_APP_SERVER || window.location.origin

const fetchDataApi = async () => {
  const response = await axios.get(`${API_URL}/api/get`, { withCredentials: true });
  return response.data;
};

export default fetchDataApi;
