import styled from "styled-components";

export const Homepage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
`;

export const HomepageContainer = styled.div`
  /* background-image: url("https://images.pexels.com/photos/531767/pexels-photo-531767.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260");
  background-position: center; 
  background-repeat: no-repeat; 
  background-size: cover;  */
  width: 100%;
  height: 100%;
  color: white;
`;
