import styled from "styled-components";

export const SelectEmployee = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
  top: 10vh;
  position: relative;
  font-weight: bold;
  padding-bottom: 10rem;
  form > label > div {
    width: 300px;
  }
`;

export const SelectElements = styled.p`
  height: 10px;
`;
