import styled from "styled-components";
import { Link } from "react-router-dom";

export const MainSidebar = styled.nav`
  position: absolute;
  top: 90px;
  width: 250px;
  height: 200px;
  color: rgb(244, 247, 250);
  background-color: rgb(34, 137, 255);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  right: ${({ sidebar }) => (sidebar ? 0 : "-100%")};
  z-index: 0;
  transition: all 0.7s ease;
  border-radius: 3px;
`;
export const SidebarContainer = styled.ul`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0.9;
`;

export const SidebarItems = styled(Link)`
  color: #fff;
  text-decoration: none;
  cursor: pointer;
  margin: 25px;
  padding: 5px;
  font-weight: bold;

  &:hover {
    background-color: black;
  }
`;
